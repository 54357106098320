import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import 'lightgallery/css/lightgallery-bundle.min.css';

// import '@fortawesome/fontawesome-pro/css/all.min.css';
// import '@fortawesome/fontawesome-pro/js/all.js';

import 'flowbite';

import 'spin.js/spin.css';
